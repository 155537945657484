import Link from 'next/link';

const PageSectionHeader = ({
  title,
  linkText,
  linkTitle,
  href,
  target,
  variant = 'default',
}: {
  title: string;
  href?: string;
  target?: string;
  linkText?: string;
  linkTitle?: string;
  variant?: 'default' | 'secondary';
}) => {
  return variant === 'default' ? (
    <div className="flex w-full flex-wrap items-center justify-center mt-4 mb-2">
      <h3 className="grow text-h3 font-semibold text-inherit sm:text-h2">
        {title}
      </h3>
      {linkText && href && (
        <Link
          href={href}
          title={linkTitle}
          target={target ?? '_self'}
          className="transition-color font-semibold text-brand-secondary transition-colors hover:text-brand-primary"
        >
          {linkText}
        </Link>
      )}
    </div>
  ) : (
    <div className="mb-[2.4rem] items-center sm:mb-[4rem] sm:flex sm:justify-between">
      <h2 className="m-auto mb-[1.6rem] block w-fit text-center text-[2.4rem] font-semibold sm:m-0 sm:inline-block sm:text-[3.2rem]">
        {title}
      </h2>
      <div className="m-auto block w-fit text-center sm:m-0 sm:inline-block">
        {linkText && href && (
          <Link
            href={href}
            title={linkTitle}
            className="transition-color font-semibold text-brand-secondary transition-colors hover:text-brand-primary"
          >
            {linkText}
          </Link>
        )}
      </div>
    </div>
  );
};

export default PageSectionHeader;
