import ImageLink from '@ui/Link/ImageLink';

interface PopularCategoriesProps {
  className?: string;
}

const PopularCategories = ({}: PopularCategoriesProps) => {
  const categories = [
    {
      href: '/c/digital-cameras',
      src: '/categories/cameras.png',
      alt: 'Browse Cameras',
      label: 'Cameras',
    },
    {
      href: '/c/photo-video-lenses',
      src: '/categories/lenses.png',
      alt: 'Shop For Lenses',
      label: 'Lenses',
    },
    {
      href: '/c/video-camcorders',
      src: '/categories/video.png',
      alt: 'Shop Video Equipment',
      label: 'Video',
    },
    {
      href: '/c/lighting-studio',
      src: '/categories/lighting.png',
      alt: 'Browse Lighting Equipment',
      label: 'Lighting',
    },
    {
      href: '/c/drones-aerial-imaging',
      src: '/categories/drones.png',
      alt: 'Shop For Drones',
      label: 'Drones',
    },
    {
      href: '/c/audio',
      src: '/categories/audio.png',
      alt: 'Browse Audio Equipment',
      label: 'Audio',
    },
    {
      href: '/c/vintage-camera-equipment',
      src: '/categories/vintage.png',
      alt: 'Shop Vintage Equipment',
      label: 'Vintage',
    },
    {
      href: '/c/computers-electronics',
      src: '/categories/computers.png',
      alt: 'Browse Computers',
      label: 'Computers',
    },
    {
      href: '/c/photo-video-accessories',
      src: '/categories/accessories.png',
      alt: 'Shop Accessories',
      label: 'Accessories',
    },
  ];
  return (
    <div className="mx-[1.6rem] flex gap-[1.6rem] overflow-x-scroll pb-4 sm:mx-0 sm:w-full sm:flex-wrap sm:justify-center sm:overflow-auto sm:pb-0">
      {categories.map((category) => (
        <ImageLink
          key={category.label}
          src={category.src}
          href={category.href}
          alt={category.alt}
          label={category.label}
          variant="square"
        />
      ))}
    </div>
  );
};

export default PopularCategories;
