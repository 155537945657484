import SafeImage from '@ui/SafeImage';
import { TOP_BRANDS } from '@util/maps/brands';
import { Brand } from '@util/types/brands';
import { getTopBrandUrl } from '@util/urlHelpers';
import { StaticImageData } from 'next/image';
import Link from 'next/link';
import cannonLogo from 'src/i/brands/Canon.webp';
import fujifilmLogo from 'src/i/brands/Fujifilm.webp';
import nikonLogo from 'src/i/brands/Nikon.webp';
import sonyLogo from 'src/i/brands/Sony.webp';
import sigmaLogo from 'src/i/brands/Sigma.webp';
import leicaLogo from 'src/i/brands/Leica.webp';
import arriLogo from 'src/i/brands/ARRI.webp';
import panasonicLogo from 'src/i/brands/Panasonic.webp';
import redLogo from 'src/i/brands/RED.webp';
const brandLinkClass = "brand-link opacity-70 transition-all duration-150 hover:opacity-100 hover:scale-125";

function getBrandAssets(b: Brand): {
  width: number;
  height: number;
  src: StaticImageData;
  brand: Brand;
} | null {
  switch (b) {
    case 'Canon':
      return {
        brand: b,
        width: 146,
        height: 54,
        src: cannonLogo,
      };
    case 'ARRI':
      return {
        brand: b,
        width: 146,
        height: 54,
        src: arriLogo,
      };
    case 'Sony':
      return {
        brand: b,
        width: 165,
        height: 62,
        src: sonyLogo,
      };
    case 'Nikon':
      return {
        brand: b,
        width: 106,
        height: 106,
        src: nikonLogo,
      };
    case 'Leica':
      return {
        brand: b,
        width: 90,
        height: 90,
        src: leicaLogo,
      };
    case 'Fujifilm':
      return {
        brand: b,
        width: 170,
        height: 64,
        src: fujifilmLogo,
      };
    case 'Panasonic':
      return {
        brand: b,
        width: 160,
        height: 60,
        src: panasonicLogo,
      };
    case 'RED':
      return {
        brand: b,
        width: 150,
        height: 55,
        src: redLogo,
      };
    default:
      return null;
  }
}

const TopBrands = () => {
  const brands = TOP_BRANDS.map(getBrandAssets);
  return (
    <>
      <div className="h-[11rem] overflow-x-auto overflow-y-hidden sm:overflow-x-hidden px-[1rem] flex items-center justify-between gap-[0.8rem]">
        {brands.map(
          (brand, index) =>
            brand && (
              <Link
                href={getTopBrandUrl(brand.brand)}
                className={brandLinkClass}
                key={brand.brand + '-' + index}
              >
                <SafeImage
                  src={brand.src.src}
                  alt={brand.brand}
                  width={brand.width}
                  height={brand.height}
                  className='object-contain max-w-[100px] sm:max-w-[100%]'
                />
              </Link>
            )
        )}
      </div>
    </>
  );
};

export default TopBrands;
