import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

const CEOMessage = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="w-full bg-black relative rounded-[1rem] overflow-hidden">
      <div className="md:hidden absolute top-4 left-4 text-orange-500 font-semibold text-[2rem] z-10 max-w-[150px] leading-tight">
        From our CEO Bill Pryor
      </div>
      <div className={`flex flex-row ${isExpanded ? 'mt-[5rem]' : ''}`}>
        <div className={`relative w-[200px] sm:w-[250px] md:w-[322px] flex-shrink-0 self-end ${isExpanded ? 'hidden' : ''}`}>
          <Image
            src="/bill.png"
            alt="Bill Pryor"
            width={322}
            height={547}
            className="object-contain w-[200px] sm:w-[250px] md:w-[322px]"
          />
        </div>
        <div className={`flex flex-col gap-6 px-4 py-8 md:py-16 md:text-lg text-[1.5rem] text-white relative ${!isExpanded ? 'max-h-[300px] overflow-hidden md:max-h-none md:overflow-visible' : ''}`}>
          <h2 className="mb-8 text-3xl md:text-4xl font-semibold hidden md:block">Welcome to GearFocus Marketplace – A Message from Bill Pryor, CEO</h2>
          <div className={`flex flex-col gap-6 text-[1.5rem] ${!isExpanded ? 'md:mb-0 mb-12' : ''}`}>
            <p>Hello GearFocus Community!</p>
            
            <p>If you&apos;re anything like me, you know gear isn&apos;t just gear — it&apos;s what brings your ideas to life. Whether you&apos;re behind the camera, in the editing bay, or prepping for your next shoot, we get it because we&apos;re right there with you.</p>
            
            <p>The idea of a pre-owned camera marketplace came out of frustration. I was upgrading my own kit, and selling my old gear felt like a hassle. I asked my co-founder, &quot;Why isn&apos;t there a better way to buy and sell great pre-owned equipment with people who actually know what they&apos;re doing?&quot; So, we built one — a marketplace by creators, for creators... and we designed it to make the process easy, safe, and transparent.</p>
            
            <p>GearFocus isn&apos;t just a store — it&apos;s a community of photographers, filmmakers, and content creators who care about the gear they sell and buy. You&apos;re not purchasing from random sellers; you&apos;re buying from fellow creators, verified by our team, who&apos;ve used and trusted their equipment.</p>
            
            <p>After years in this industry, I know how much the right tools can shape a project. That&apos;s why we&apos;re dedicated to helping you buy and sell your gear with zero hassle. From pre-owned cameras and lenses to lighting, drones, and computers, GearFocus is here to help you keep creating without blowing your budget.</p>
            
            <p>I appreciate you choosing GearFocus and being part of this growing community. Whether you&apos;re selling your old gear to upgrade or just looking for that perfect lens, we&apos;re here to help make it happen.</p>
            
            <p>Thanks for trusting us with your craft. We can&apos;t wait to see what you create next.</p>
            
            <div>
              <p>Best,</p>
              <p>Bill Pryor</p>
              <p>CEO, GearFocus Marketplace</p>
            </div>

            <p className="text-sm italic text-gray-400">
              Headshot Courtesy of{' '}
              <Link href="https://peterhurley.com" className="text-blue-500 hover:underline">
                Peter Hurley
              </Link>
              {' & '}
              <Link href="https://theheadshotcrew.com" className="text-blue-500 hover:underline">
                The Headshot Crew
              </Link>
            </p>
          </div>
          {!isExpanded && (
            <button 
              onClick={() => setIsExpanded(true)}
              className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via-black to-transparent py-4 text-blue-500 md:hidden"
            >
              Show more
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CEOMessage; 