import { StarIcon } from '@c/icons';
import { GoogleIcon } from '@c/icons';
import SafeImage from './SafeImage';
import { useState } from 'react';

interface ReviewProps {
  img: string;
  name: string;
  content: string;
  googleReviewLink?: string;
}

const Review = ({ img, name, content, googleReviewLink }: ReviewProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="flex h-fit w-[100vw] mt-4 flex-col justify-center md:w-[50rem] lg:w-[80rem] xl:w-[120rem] px-16">
      <div className="w-full">
        <div className="flex flex-col flex-wrap items-start gap-[0.8rem] sm:flex-row sm:flex-nowrap sm:items-center">
          <div className="mr-[1.6rem] h-[4.8rem] w-[4.8rem] overflow-clip rounded-full bg-gray-200">
            <SafeImage src={img} alt="Not Found" />
          </div>
          <div className="flex flex-col gap-[0.8rem]">
            <div className="font-semibold text-orange-500 sm:text-inherit">{name}</div>
            {/* stars */}
            <div className="flex items-center">
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
            </div>
          </div>
          {/* view on google, far right */}
          <div className="grow"></div>
          {googleReviewLink && (
            <div className="flex items-center gap-[0.8rem] font-semibold text-brand-secondary">
              <GoogleIcon />
              View on Google
            </div>
          )}
        </div>
        {/* content */}
        <div className="mt-[3.2rem]">
          <div
            className={`italic text-gray-500 ${
              !isExpanded ? 'max-h-[250px] overflow-hidden text-ellipsis sm:max-h-none sm:overflow-visible' : ''
            }`}
          >
            {content}
          </div>
          {content.length > 250 && (
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="mt-2 text-orange-500 hover:text-orange-600 sm:hidden"
            >
              {isExpanded ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Review;
